const Router = require('nextjs-dynamic-routes');

const router = new Router();

router.add({ name: 'paths', pattern: '/:locale/paths/:profession?/:name?/:id?' });
router.add({ name: 'about', pattern: '/:locale/about/' });
router.add({ name: 'careers', pattern: '/:locale/careers' });
router.add({ name: 'terms', pattern: '/:locale/terms/' });
router.add({ name: 'features', pattern: '/:locale/features/' });
router.add({ name: 'contactUs', pattern: '/:locale/contactUs/' });
router.add({ name: 'privacy', pattern: '/:locale/privacy/' });
router.add({ name: 'pricing', pattern: '/:locale/pricing/' });
router.add({ name: 'ourCommunities', pattern: '/:locale/ourCommunities/' });
router.add({ name: 'blog', pattern: '/:locale/blog/' });
router.add({ name: 'blogCategory', pattern: '/:locale/blog/:category' });
router.add({ name: 'blogSubCategory', pattern: '/:locale/blog/:category/:subCategory' });
router.add({ name: 'profile', pattern: '/:locale/profile/' });
router.add({ name: 'myCourses', pattern: '/:locale/courses/' });
router.add({ name: 'mySchedules', page: '/myCourses', pattern: '/:locale/courses/schedules/' });
router.add({ name: 'article', pattern: '/:locale/blog/:year/:month/:day/:url/' });
router.add({ name: 'blogLanding', pattern: '/:locale/l/:url/' });
router.add({ name: 'addCourseIntro', pattern: '/:locale/intro/' });
router.add({
  name: 'createCourseSchool',
  pattern: '/:locale/create-course-school/:step?/:shortId?/:courseShortId?',
});
router.add({ name: 'discount', pattern: '/:locale/discounted-and-free-courses/' });
router.add({
  name: 'search',
  page: '/search',
  pattern: '/:locale/search/',
});
router.add({
  name: 'landing',
  pattern: '/:locale/category/:category/',
});
router.add({ name: 'subLanding', pattern: '/:locale/category/:category/:type/' });

router.add({ name: 'orders', pattern: '/:locale/orders/:order?/:status?/' });
router.add({ name: 'favourites', pattern: '/:locale/favourites/' });
router.add({ name: 'register', pattern: '/:locale/register/:shortId?/' });
router.add({ name: 'rewards', pattern: '/:locale/rewards/' });
router.add({ name: 'profile', pattern: '/:locale/test/' });
router.add({ name: 'school', pattern: '/:locale/schools-edit/:school/:id/' });
router.add({ name: 'createSchool', pattern: '/:locale/schools/create' });
router.add({ name: 'schoolShow', pattern: '/:locale/schools/:school/:id/:claim?' });
router.add({ name: 'createCourse', pattern: '/:locale/courses/create/:school?/:course?/' });
router.add({ name: 'course', pattern: '/:locale/courses-edit/:school/:course/:id/' });
router.add({ name: 'courseShow', pattern: '/:locale/courses/:school/:course/:id/:register?' });
router.add({ name: 'success', pattern: '/:locale/success/:id/:externalId?' });
router.add({ name: 'jobs', pattern: '/jobs' });

router.add({ name: 'index', pattern: '/:locale?/' });
router.add({
  name: 'index',
  page: '/index',
  pattern: '/:locale/auth/one-time-token/:token',
});
router.add({
  name: 'authCallback',
  page: '/authCallback',
  pattern: '/:locale?/auth-callback/:source',
});
router.add({ name: 'auth', pattern: '/:locale/auth/' });

router.add({ name: '_error', pattern: '*' });

module.exports = router;
